@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #f97316;
  --color-secondary: #3b82f6;
  --color-background: #fffbeb;
  --color-text: #1f2937;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

.bg-primary {
  background-color: var(--color-primary);
}

.text-primary {
  color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.text-secondary {
  color: var(--color-secondary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
.player-container {
    padding: 10px;
    max-width: 100%;
  }

  .player-content {
    flex-direction: column;
    align-items: center;
  }

  .player-image {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    padding: 10px 0;
  }
  /* General layout adjustments */
  .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }

  /* Navbar adjustments */
  nav {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .nav-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-buttons {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  /* Player container adjustments */
  #player-container {
    padding: 10px;
    max-width: 100%;
  }

  .player-content {
    flex-direction: column;
    align-items: center;
  }

  .player-image {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

.player-info {
  text-align: center;
  width: 100%;
}


.player-info h3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Adjust this value based on your font size and line height */
}

  /* Categories adjustments */
  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    padding: 10px 0;
  }

  .category-button {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
    font-size: 12px;
  }

  /* Track list adjustments */
  .track-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

.track-list {
  width: 100%;
  overflow-x: hidden;
}
  
  
.track-list .font-medium {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust this value based on your font size and line height */
}
  
  .track-list > div {
  width: 100%;
}
  
  .track-info {
    width: 100%;
    margin-bottom: 10px;
  }

  .track-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Create Bhajan form adjustments */
  .create-bhajan-form {
    padding: 15px;
  }

  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Top songs adjustments */
  .top-songs {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 15px;
  }

  .top-song-item {
    display: inline-block;
    width: 200px;
    margin-right: 15px;
  }
  
    .top-songs-dots {
    display: flex;
  }
  
    nav {
    padding: 1rem 0.5rem;
  }

  nav .container {
    padding: 0 0.5rem;
  }

  nav h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  nav .flex-col {
    width: 100%;
  }

  nav button {
    width: 100%;
    margin-top: 0.5rem;
  }

  nav .space-x-4 > * + * {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  nav .space-y-4 > * + * {
    margin-top: 1rem;
  }

  nav .items-center {
    justify-content: center;
  }

  nav .rounded-full {
    border-radius: 0.5rem;
  }

  nav .bg-white {
    width: 100%;
    justify-content: center;
    padding: 0.5rem;
  }
  
}

/* Additional global styles to ensure no horizontal scrolling */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.track-info-button { 
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.top-songs {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.top-songs::-webkit-scrollbar {
  display: none;
}

.top-song-item {
  min-width: 250px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px;
  background: #ffffff;
}

.top-song-item h4 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Adjust this value based on your font size and line height */
}

.top-song-item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-songs-dots {
  display: none;
}

@media (max-width: 768px) {
  .top-songs-dots {
    display: flex;
  }
}

/* Add these styles at the end of your index.css file */
.audio-visualizer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (max-width: 768px) {
  .audio-visualizer {
    height: 100px;
  }
}

@media (min-width: 769px) {
  .audio-visualizer {
    height: 150px;
  }
}

/* Add after all existing CSS */

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.slide-in {
  animation: slideIn 0.3s ease-in-out;
}

.hover-scale {
  transition: transform 0.2s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}


.description-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.description-content h1 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1a202c;
}

.description-content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: #2d3748;
}

.description-content p {
  margin-bottom: 1rem;
}

.description-content ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.description-content li {
  margin-bottom: 0.5rem;
}

.description-content a {
  color: #f97316;  /* Orange color, adjust as needed */
  text-decoration: underline;
  transition: color 0.3s ease;
}

.description-content a:hover {
  color: #c2410c;  /* Darker orange for hover state */
  text-decoration: none;
}

.description-content strong {
  font-weight: 600;
  color: #2d3748;
}